@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-grids/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-treegrid/styles/material.css';
/* @import '../../../node_modules/@syncfusion/ej2-navigations/styles/treeview/material.css'; */

.e-ddl.e-input-group.e-control-wrapper.e-input-focus::before,
.e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
   background: none;
   animation: none;
   transition: none;
}

span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input::before,
span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input::after {
   animation: none;
   transition: none;
   background-color: none;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
   background-color: none;
   color: green;
   animation: none;
   transition: none;
}

.orientationcss .e-headercelldiv {
   writing-mode: vertical-rl;
   text-orientation: mixed;
}
