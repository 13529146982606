/*         rbg          hex
dark blue   9 43 73     #092B49
med dark    0 84 139    #00548B
med light   11 125 187  #0B7DBB
light blue  4 178 226   #04B2E2

secondary colours
e2045d - pink
492009 - brown
bb460b - orange
e2b604 - yellow
e9edee - cream
*/

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: #7a7d82 !important;
   overflow: unset;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cardIcon {
   color: white;
   background-color: #0b7dbb;
   float: left;
   padding: 10px 15px;
   margin-top: -20px;
   margin-right: 15px;
   border-radius: 3px;
}

.errorinput {
   border: 1px solid #FF0000 !important;
}

.cardHeader {
   color: #00548b;
   font-weight: bold;
   font-size: x-large;
   flex: 0 0 0 0 !important;
   padding-bottom: 0px !important;
}

.mainGrid {
   z-index: 3;
   width: 90% !important;
   margin-left: 5% !important;
}

.shortTabs {
   padding-left: 0px !important;
   padding-right: 0px !important;
   min-width: 30px !important;
   width: 60px !important;
}

.avatar {
   background: #0b7dbb !important;
   height: 50px !important;
   width: 50px !important;
   border-radius: 5px !important;
}

.avatarIcon {
   height: 30px !important;
   width: 30px !important;
}

.cardHeaderButton {
   color: white !important;
   background: #4caf50 !important;
}

.bottomButtonGroup {
   padding-top: 25px;
}

.greenButton {
   color: white !important;
   background-color: #4caf50 !important;
   margin: 5px !important;
}

.redButton {
   color: white !important;
   background-color: #ef5350 !important;
   margin-right: 5px !important;
}

.orangeButton {
   color: white !important;
   background-color: #ff9800 !important;
}

.grayButton {
   color: black !important;
   background-color: #d5d5d5 !important;
   margin: 5px !important;
   width: fit-content;
}

.cardActions {
   justify-content: space-between;
}

.MuiCardHeader-action {
   margin: 0 !important;
   align-self: center !important;
}

.textField {
   min-width: 200px !important;
}

.hidden {
   visibility: 'hidden' !important;
   display: none !important;
}

.visible {
   visibility: 'visible' !important;
}

.sizedModal {
   width: 75% !important;
   padding-top: 50px !important;
   margin: auto !important;
   overflow: auto;
}

a {
   color: #0b7dbb;
}

a:hover {
   color: #0b7dbb !important;
   text-decoration: underline !important;
}

a:visited {
   color: #0b7dbb;
}

a:link {
   color: #0b7dbb;
}

a:active {
   color: #0b7dbb;
}

.buttonlink {
   border: none;
   color: black !important;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   cursor: pointer;
}
.buttonlink:hover {
   color: black !important;
   text-decoration: none !important;
}
.buttonlink:visited {
   color: #0b7dbb !important;
}

.rt-resizable-header-content {
   padding-right: 15px !important;
}

.rt-th {
   text-align: left !important;
}

.center {
   margin: auto;
   width: 100%;
}

.rotated180 {
   transform: rotate(180deg);
   /* Equal to rotateZ(45deg) */
}

.MuiTab-root.Mui-selected {
   background-color: #0b7dbb !important;
   color: white !important;
}

.column {
   background-color: #edf1f2;
   margin: 20px;
   padding: 10px;
   border-radius: 15px;
   width: 500px;
   /* height: 370px; */
   margin: auto;
   text-align: center;
}

.watermarkLine {
   position: relative;
}

.watermarkLine:after {
   content: '';
   display: block;
   pointer-events: none;
   width: 210px;
   height: 220px;
   position: absolute;
   top: 0px;
   right: 30px;
   background-image: url('assets/images/InterfaceLogoColour200x65.png');
   background-size: auto;
   background-position: 0px 150px;
   background-repeat: no-repeat;
   opacity: 0.2;
}

.watermarkBar {
   position: relative;
}

.watermarkBar:after {
   content: '';
   display: block;
   pointer-events: none;
   width: 460px;
   height: 150px;
   position: absolute;
   top: 40px;
   left: 100px;
   background-image: url('assets/images/InterfaceLogoColour460x150.png');
   background-size: auto;

   background-repeat: no-repeat;
   opacity: 0.2;
}

.css-fix {
   white-space: pre-wrap;
   /* or pre-line */
}

.dot {
   height: 20px;
   width: 20px;
   background-color: #bbb;
   border-radius: 50%;
   display: inline-flex;
}

.e-hide {
   display: none !important;
}
.e-none {
   display: none !important;
}

/* .e-headercell.customcss{
    writing-mode: vertical-rl;
} */

/* .e-grid .e-rowcell{
    background-color: #ecedee;
} */
/* .e-grid .e-headercell {
    writing-mode: vertical-rl !important;
    display: table-cell;
    vertical-align: bottom;
    text-align: end !important;
}

.e-headercelldiv {
    text-align: end !important;
    padding-bottom: 10px;
} */

div.MuiTabs-flexContainer {
   flex-wrap: wrap;
}

.vertical {
   writing-mode: vertical-rl;
   text-orientation: mixed;
}

.nopadding {
   padding: 5px !important;
}

.borderfull {
   border: thin solid black;
   width: 100px;
   height: 50px;
}

.horizontalAlign {
   align-items: center;
   display: flex;
   flex-direction: row;
}

#search-clear:hover {
   cursor: pointer;
}

/* Allow dialog overflow*/
.css-bhp9pd-MuiPaper-root-MuiCard-root {
   overflow: auto !important;
}
.css-s18byi {
   overflow: auto !important;
}
